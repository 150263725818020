import React from 'react'

const About = () => {
    return (
        <div>
            Hello!
            My name is Julian and I like using technology to solve self-inflicted problems.
        </div>

    )
}

export default About
